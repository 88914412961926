/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/
/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/
@font-face {
  font-family: 'Satoshi-Variable';
  src: url('../../fonts/Satoshi-Variable.woff2') format('woff2'), url('../../fonts/Satoshi-Variable.woff') format('woff'), url('../../fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/
@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('../../fonts/Satoshi-VariableItalic.woff2') format('woff2'), url('../../fonts/Satoshi-VariableItalic.woff') format('woff'), url('../../fonts/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Light';
  src: url('../../fonts/Satoshi-Light.woff2') format('woff2'), url('../../fonts/Satoshi-Light.woff') format('woff'), url('../../fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('../../fonts/Satoshi-LightItalic.woff2') format('woff2'), url('../../fonts/Satoshi-LightItalic.woff') format('woff'), url('../../fonts/Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Regular';
  src: url('../../fonts/Satoshi-Regular.woff2') format('woff2'), url('../../fonts/Satoshi-Regular.woff') format('woff'), url('../../fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-Italic';
  src: url('../../fonts/Satoshi-Italic.woff2') format('woff2'), url('../../fonts/Satoshi-Italic.woff') format('woff'), url('../../fonts/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Medium';
  src: url('../../fonts/Satoshi-Medium.woff2') format('woff2'), url('../../fonts/Satoshi-Medium.woff') format('woff'), url('../../fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('../../fonts/Satoshi-MediumItalic.woff2') format('woff2'), url('../../fonts/Satoshi-MediumItalic.woff') format('woff'), url('../../fonts/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Bold';
  src: url('../../fonts/Satoshi-Bold.woff2') format('woff2'), url('../../fonts/Satoshi-Bold.woff') format('woff'), url('../../fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-BoldItalic';
  src: url('../../fonts/Satoshi-BoldItalic.woff2') format('woff2'), url('../../fonts/Satoshi-BoldItalic.woff') format('woff'), url('../../fonts/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Black';
  src: url('../../fonts/Satoshi-Black.woff2') format('woff2'), url('../../fonts/Satoshi-Black.woff') format('woff'), url('../../fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('../../fonts/Satoshi-BlackItalic.woff2') format('woff2'), url('../../fonts/Satoshi-BlackItalic.woff') format('woff'), url('../../fonts/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
body {
  font-family: 'Satoshi-Variable';
}
.light-theme {
  --primary-color: #16885f;
  --secondary-color: #E8F3EF;
  --background-color: #ffffff;
  --text-color: #333;
  --alternate-text-color: #ffffff;
  --toolbar-color: #f0f6ff;
  --content-color: #F2F4F7;
  --box-showdow-color: rgba(203, 201, 201, 0.297);
  --border-color: #25282d;
  --icon-color: #4F555E;
  --layout-background: #6ba6fe4d;
}
.dark-theme {
  --primary-color: #34495e;
  --secondary-color: #27ae60;
  --background-color: #2c3e50;
  --text-color: #ffffff;
  --alternate-text-color: #333333;
  --toolbar-color: #2c3e50;
  --content-color: #333436;
  --box-showdow-color: rgba(167, 164, 164, 0.471);
  --border-color: #ffffff;
  --icon-color: #ffffff;
  --layout-background: #6ba6fe4d;
}
.preview__card__shimmer {
  width: 9.643em;
  height: 5em;
  border-radius: 8px;
}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: rgba(189, 200, 206);
  }
  100% {
    background-color: #e2e7e9;
  }
}
.navbar {
  box-shadow: none;
  background-color: var(--background-color);
  color: var(--text-color);
}
.navbar__logo__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.65em;
  font-size: 18px;
}
.navbar__name {
  color: black;
  opacity: 0.4;
  font-size: 20px;
}
.navbar__name__divider {
  width: 0.125em;
  height: 0.9em;
  background-color: black;
  opacity: 0.4;
  display: inline-block;
}
.navbar__item__container {
  gap: 0.714em;
}
.navbar__item__icon {
  font-size: 20px;
  color: var(--text-color);
  cursor: pointer;
}
.navbar__divider {
  margin: 0.357em 0.714em;
}
.bp4-navbar {
  padding: 0em 0.357em;
  box-shadow: none;
  background-color: var(--background-color);
}
.btn {
  width: 7.5em;
  height: 2.6em;
  border-radius: 8px;
  padding: 0.714em;
  font-size: 14px;
  font-family: 'Satoshi-Bold';
  font-weight: 700;
  transition: 0.2s ease-out;
  box-shadow: none;
  outline: none;
}
.btn__primary {
  background-color: var(--primary-color);
  color: var(--alternate-text-color);
}
.btn__secondary {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.btn__primary:hover {
  background-color: #00442d;
  transition: 0.2s ease-in;
}
.btn__secondary:hover {
  background-color: #cce6df;
  transition: 0.2s ease-in;
}
.btn__download {
  width: 100%;
  margin-top: 4.286em;
  padding: 1.786em;
  margin-bottom: 0.714em;
}
.btn__disable {
  border: 1px solid rgba(167, 164, 164, 0.471);
}
.close__button {
  font-size: 20px;
  color: var(--icon-color);
  cursor: pointer;
}
.confirm__button {
  width: 21.429em;
  height: 2.857em;
  margin: 0.7em;
}
.radio {
  margin: 1.429em 0em;
}
.radio__btn {
  border: 1px solid #c3c3c384;
  border-radius: 16px;
}
.radio__content {
  display: inline-block;
  justify-content: space-between;
}
.radio__header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0.714em;
}
.bp4-control input:checked ~ .bp4-control-indicator {
  background-color: #16885f;
  margin: 0em;
}
.bp4-control:not(.bp4-align-right) .bp4-control-indicator {
  margin: 0em;
}
.card {
  border-radius: 8px;
}
.card__slide {
  width: 9.5em;
  height: 7em;
  margin-bottom: 1.429em;
}
.card__slide--active {
  border: 1.15px solid #25282D;
  box-shadow: 0 0 20px 2px var(--box-showdow-color);
}
.card__product {
  display: flex;
  flex-direction: column;
  height: 12.143em;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.1s ease-in;
  padding: 0em;
  position: relative;
}
.card__template {
  background-color: #f5f5f5;
  border: none;
  height: 14.286em;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;
  margin: 1.429em 0em;
}
.card__template__shimmer {
  height: 11.071em;
  width: 17.857em;
}
.card__product--active {
  border: 1px solid var(--border-color);
}
.card__product--inactive {
  border: 1px solid transparent;
}
.card__product:hover {
  box-shadow: 0 0 20px 0px rgba(179, 177, 177, 0.353);
}
.card__layout {
  background-color: #f5f5f5;
  border: none;
  height: 14.286em;
  width: 100%;
  cursor: pointer;
  position: relative;
}
.card__layout__shimmer {
  height: 11em;
  width: 100%;
}
.card__layout--active {
  border: 2px solid var(--border-color);
}
.card__layout--inactive {
  border: 1px solid transparent;
}
.card__layout:hover {
  box-shadow: 0 0 20px 2px rgba(179, 177, 177, 0.551);
}
.card__layout__layer {
  background-color: grey ;
  opacity: 0.5;
  border-radius: 8px;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.3s ease-in-out;
  width: 100%;
}
.card__upload {
  width: 9.286em;
  height: 8.929em;
  margin: 0.071em;
  cursor: pointer;
  position: relative;
}
.preview__total_slides {
  padding-top: 0.571em;
  padding-left: 0.571em;
}
.preview__card {
  overflow: scroll;
  overflow-x: hidden;
  padding: 1.786em;
}
.preview__card__container {
  display: flex;
  gap: 0.857em;
  margin: 1.071em 0em;
  cursor: pointer;
}
.preview__card__number {
  background-color: var(--text-color);
  opacity: 0.8;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  color: var(--alternate-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel {
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  gap: 20px;
}
.carousel:hover .carousel__container {
  animation: carousel-scroll 2s linear infinite;
}
@keyframes carousel-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
.modal {
  padding: 0em 1.071em;
  border-radius: 16px;
  background-color: white;
}
.modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.714em 1.429em;
}
.modal__title {
  display: flex;
}
.modal__loading__container {
  padding: 1.429em;
  text-align: center;
}
.modal__loading__text {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1em;
  text-align: center;
}
.modal__loading__subText {
  margin-bottom: 2em;
  opacity: 0.6;
}
.modal__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.product__attribute__modal {
  width: 45.857em;
}
.warning__modal {
  width: 32.143em;
}
.layout__warning__modal__message {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 18px;
  line-height: 1.6;
}
.bp4-dialog-body-scroll-container {
  overflow: hidden;
  max-height: fit-content;
}
.section-side-panel {
  height: calc(100vh - 7em);
  background-color: var(--background-color);
  border-radius: 20px;
  margin-top: 1.071em;
  margin-left: 1.429em;
  box-shadow: 0 0 20px 0px var(--box-showdow-color);
  overflow: scroll;
}
.section-side-panel::-webkit-scrollbar {
  display: none;
}
.open-panel {
  height: calc(100vh - 7em);
  background-color: var(--background-color);
  border-radius: 20px;
  margin-top: 1.071em;
  margin-left: 1.429em;
  box-shadow: 0 0 20px 0px var(--box-showdow-color);
  overflow: scroll;
  width: 23em;
  opacity: 1;
  transition: 0.2s ease-in-out;
}
.open-panel::-webkit-scrollbar {
  display: none;
}
.close-panel {
  width: 0em;
  opacity: 0;
  transform: translateX(25em);
  transition: 0.2s ease-in-out;
}
.panel-title-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.357em;
  padding: 20px 20px 0px 20px;
}
.panel-title {
  font-size: 16px;
  font-weight: 700;
}
.scrollable-container {
  height: calc(100% - 2.5em);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1em;
}
.scrollable-container::-webkit-scrollbar {
  display: none;
}
.product__filter {
  display: flex;
  align-items: center;
  gap: 0.714em;
}
.product__filter__dropdown {
  width: 20.357em;
  height: 18.8em;
  background-color: var(--background-color);
  border-radius: 12px;
  box-shadow: 0 0 20px 0px rgba(203, 201, 201, 0.297);
  padding: 1.429em;
}
.product__filter__sorting {
  border-radius: 12px;
  box-shadow: 0 0 20px 0px rgba(203, 201, 201, 0.297);
}
.product__filter__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.product__icon {
  font-size: 45px;
  cursor: pointer;
  transition: 0.2s ease-out;
  color: var(--border-color);
  border: 1px solid #c5c7ca;
  border-radius: 8px;
  width: 1.1em;
  padding: 0.25em;
}
.product__icon:hover {
  transition: 0.2s ease-in;
  transform: scale(1.04);
}
.product__attribute {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 8px;
  width: 100%;
  padding: 1.1em;
}
.product__change__attribute {
  color: var(--primary-color);
  font-weight: 700;
  cursor: pointer;
}
.product__change__attribute--inactive {
  color: #94a59f;
}
.product__modal__container {
  display: flex;
  gap: 20px;
}
.product__modal__container .bp4-control:not(.bp4-align-right) {
  padding: 0em;
}
.product__modal__btn {
  display: flex;
  justify-content: center;
  padding: 1.429em;
}
.product__modal__checkbox {
  max-height: 27.143em;
  overflow-y: scroll;
  width: 20em;
}
.product__modal__dnd {
  height: 100%;
  padding: 1.429em;
  background-color: #F7F8FA;
  border-radius: 12px;
  width: 20em;
}
.product__modal__dnd__area {
  height: 27.143em;
  overflow-y: scroll;
}
.product__modal__dnd__chip {
  padding: 1.071em;
  display: flex;
  align-items: center;
}
.product__card__grid {
  display: grid;
  grid-template-columns: repeat(2, 3fr);
  row-gap: 5px;
  column-gap: 12px;
  margin: 1.429em 0em;
  grid-auto-flow: dense;
}
.product__card__collapse {
  margin-top: 0.313em;
  grid-column: 1 / -1;
  background-color: rgba(91, 91, 91, 0.881);
  border-radius: 8px;
  cursor: pointer;
}
.product__collapse__container {
  display: flex;
  justify-content: space-between;
  padding: 0.714em;
  color: white;
}
.product__collapse__icon {
  position: absolute;
  color: rgba(91, 91, 91, 0.881);
  top: -13px;
}
.product__carousel {
  height: 8.125em;
  display: flex;
  gap: 15px;
  border-radius: 6px;
  color: var(--text-color);
  margin-bottom: 1.071em;
  padding: 1rem;
  overflow: hidden;
  scroll-behavior: smooth;
  position: relative;
}
.product__carousel::-webkit-scrollbar {
  display: none;
}
.product__carousel__item {
  border-radius: 6px;
  overflow: hidden;
}
.product__carousel__img {
  width: 7.143em;
  height: 7.143em;
  object-fit: cover;
  border-radius: 8px;
}
.product__carousel__img__shimmer {
  width: 7.143em;
  height: 7.143em;
  object-fit: cover;
  border-radius: 8px;
}
.product__card__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product__img__container {
  position: relative;
  overflow: hidden;
  height: 21.429em;
}
.product__card__content {
  padding: 0.714em 0.357em;
}
.bp4-popover2 .bp4-popover2-content,
.bp4-menu {
  border-radius: 12px;
  padding: 0.357em;
}
.bp4-menu-item {
  padding: 0.714em 0.357em;
}
.bp4-menu-item:hover {
  background-color: var(--secondary-color);
}
.bp4-menu-item.bp4-selected,
.bp4-menu-item.bp4-selected:hover,
.bp4-menu-item.bp4-selected:active {
  background-color: #E8F3EF;
  color: black;
}
.input {
  border-radius: 8px;
  border: 1px solid #c5c7ca;
  font-size: 15px;
  margin: 1.429em 0em;
  width: 100%;
  height: 3em;
  padding: 0.643em 0.286em 0.643em 2.857em;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(79, 85, 94)' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  box-shadow: none;
  outline: none;
}
.input__error__msg {
  color: red;
  padding: 0em;
  margin: 0em;
}
.input::placeholder {
  color: #676D77;
}
.input__title {
  font-weight: 500;
}
.select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 3em;
  border-radius: 12px;
  font-size: 14px;
  padding: 0.8em;
  margin: 0.6em 0em;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}
.select__scrollable {
  max-height: 15em;
  overflow-y: auto;
}
.filter-chip {
  display: inline-flex;
  align-items: center;
  padding: 0.5em;
  border-radius: 50px;
  background-color: #eee;
  margin-right: 0.5em;
  margin-bottom: 0.8em;
}
.remove-btn {
  border: none;
  background: transparent;
  color: #999;
  margin-left: 0.5em;
  font-size: 0.8em;
  cursor: pointer;
}
.remove-btn:hover {
  color: #333;
}
.img__chip {
  background-color: rgba(0, 0, 0, 0.4);
  width: 2.714em;
  height: 1.571em;
  border-radius: 50px;
  position: absolute;
  left: 0.714em;
  top: 0.714em;
  color: white;
  z-index: 1;
}
.img__chip__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.25em;
  gap: 5px;
}
.silder {
  width: 100%;
  text-align: center;
  overflow: hidden;
  margin-bottom: 1.429em;
}
.silder::-webkit-scrollbar {
  display: none;
}
.silder__container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  gap: 10px;
  overflow-y: hidden;
}
.layout__chips {
  height: 2.286em;
  width: 6.143em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0em 0.347em 0em 0.347em;
  border-radius: 40px;
  margin: 0em 0.347em;
  background: var(--layout-background);
  cursor: pointer;
}
.template__chips {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 14.286em;
  height: 10em;
  border-radius: 8px;
  position: relative;
  margin: 0em 0.357em;
  cursor: pointer;
  overflow: hidden;
}
.template__chips__shimmer {
  width: 14.286em;
  height: 10em;
  object-fit: cover;
  box-shadow: 0 0 20px 2px var(--box-showdow-color);
  border-radius: 8px;
}
.template__chips__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 0 20px 2px var(--box-showdow-color);
  border-radius: 8px;
  margin-bottom: 8px;
}
.template__chips__text {
  text-align: left;
  display: block;
  font-weight: 500;
  margin: 0em 0.357em;
}
.spinner {
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid #16885F;
  /* Blue */
  border-radius: 50%;
  width: 3.571em;
  height: 3.571em;
  animation: spin 0.5s linear infinite;
}
.spinner__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.714em 0em;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
  gap: 20px;
  line-height: 1.5;
}
.screen__img {
  width: 50%;
}
.screen__text {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  width: 21em;
}
.screen__sub__text {
  font-size: 16px;
  text-align: center;
  opacity: 0.6;
}
.screen__button__container {
  display: flex;
  gap: 15px;
}
.container {
  padding: 0.357em 1.786em;
  box-shadow: none;
}
.container__layout {
  display: flex;
}
.container__workspace {
  flex: 1;
}
.container .section__tool__bar {
  display: flex;
  gap: 0.5em;
  color: var(--text-color);
  font-weight: 700;
  cursor: pointer;
  margin: 0.5em;
  transition: 0.2s ease-in-out;
}
.container .section__tool__bar--active {
  background-color: #F2F6E7;
  padding: 0.571em;
  border-radius: 8px;
  color: #000000;
}
.container .section__tool__bar--inactive {
  background-color: transparent;
}
.container .section__tool__divider:last-child {
  display: none;
}
.container .section__tool__icon {
  font-size: 20px;
  color: var(--text-color);
  cursor: pointer;
}
.go4153082118 {
  background-color: var(--toolbar-color);
  border-radius: 64px;
  padding: 0em 0.35em;
  box-shadow: none;
  color: var(--text-color);
}
.bp4-button.bp4-minimal:active,
.bp4-button.bp4-minimal.bp4-active {
  box-shadow: none;
  outline: none;
}
.go3009151065 {
  background-color: var(--content-color);
  border-radius: 20px;
  margin-top: 1.071em;
}
.bp4-toast {
  align-items: center;
  border-radius: 10px;
}
.reload__button__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.714em 0em;
}
.input__field__container {
  display: flex;
  flex-direction: column;
  gap: 0.714em;
  margin-bottom: 2.857em;
}
.bp4-tooltip2 {
  border-radius: 4px;
}
.bp4-tooltip2 .bp4-popover2-content {
  padding: 0.286em 0.571em 0.286em 0.571em;
  border-radius: 4px;
  z-index: 10;
}
.bp4-transition-container .bp4-popover-enter-done {
  padding: 0.286em 0.571em 0.286em 0.571em;
  border-radius: 4px;
  z-index: 5;
}
.bp4-tab-list {
  padding-bottom: 1.429em;
  box-shadow: none;
  outline: none;
}
.bp4-tab-panel_undefined_font {
  box-shadow: none;
  outline: none;
}
.bp4-tab {
  box-shadow: none;
  outline: none;
}
input[type=checkbox] {
  accent-color: #16885F;
}
.custom__checkbox {
  outline: none;
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.bp4-input-group {
  margin: 0.714em 0em;
}
.bp4-input-group .bp4-input:not(:first-child) {
  border-radius: 8px;
  margin-top: 0.714em;
  height: 2.857em;
  border: 1px solid rgba(203, 201, 201, 0.297);
}
.bp4-input-group .bp4-input-action:first-child,
.bp4-input-group > .bp4-input-left-container:first-child,
.bp4-input-group > .bp4-button:first-child,
.bp4-input-group > .bp4-icon:first-child {
  top: 15px;
}
.bp4-button {
  height: 2.6em;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Satoshi-Bold';
  font-weight: 700;
  transition: 0.2s ease-out;
}
.bp4-overflow-list {
  align-items: center;
  padding: 0.357em;
}
.upload__container {
  position: relative;
  overflow: hidden;
  display: inline-block;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  row-gap: 15px;
  margin-top: 1.429em;
}
.upload__container input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 9.643em;
  width: 9.643em;
  cursor: pointer;
}
.upload__btn {
  cursor: pointer;
  width: 9.643em;
  height: 9.643em;
  background-color: #e8f3ef;
  border-radius: 8px;
  border: none;
}
.upload__icon {
  color: #096645;
  font-size: 35px;
}
.upload__delete__button {
  position: absolute;
  right: 8px;
  top: 5px;
  font-size: 15px;
  color: var(--icon-color);
  z-index: 1;
}
.upload__delete__button:hover {
  color: grey;
  transition: 0.2s ease-in;
}
