.radio {
    margin:@radio-margin;
  
    &__btn {
      border:@border-width-default @border-type-default @radio-border-color;
      border-radius: @border-radius-large;
    }
  
    &__content {
      display: inline-block;
      justify-content: space-between;
    }
  
    &__header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: @radio-header-margin;
    }
  }

  .bp4-control input:checked ~ .bp4-control-indicator {
    background-color: @radio-button-active-background-color;
    margin: @radio-button-margin;
  }
  
  .bp4-control:not(.bp4-align-right) .bp4-control-indicator {
    margin: @radio-button-margin;
  }
   