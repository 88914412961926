.upload {
  &__container {
    position: relative;
    overflow: hidden;
    display: inline-block;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    row-gap: 15px;
    margin-top: 1.429em;
  }

  &__container input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 9.643em;
    width: 9.643em;
    cursor: pointer;
  }

  &__btn {
    cursor: pointer;
    width: 9.643em;
    height: 9.643em;
    background-color: #e8f3ef;
    border-radius: 8px;
    border: none;
  }

  &__icon {
    color: #096645;
    font-size: 35px;
  }

  &__delete__button{
    position: absolute;
    right: 8px;
    top: 5px;
    font-size: 15px;
    color: var(--icon-color);
    z-index: 1;
  }

  &__delete__button:hover{
      color: grey;
      transition: 0.2s ease-in;
  }
}
