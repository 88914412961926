.container {
  padding: @container-padding;
  box-shadow: none;

  &__layout {
    display: flex;
  }

  &__workspace {
    flex: 1;
  }

  //Section tool bar
  .section__tool {
    &__bar {
      display: flex;
      gap: @section-tool-gap;
      color: var(--text-color);
      font-weight: @font-weight-default;
      cursor: pointer;
      margin: @section-tool-margin;
      transition: 0.2s ease-in-out;
    }

    &__bar--active {
      background-color: @section-tool-active-background-color;
      padding: @section-tool-active-padding;
      border-radius: @border-radius-medium;
      color: @section-tool-active-color;
    }

    &__bar--inactive {
      background-color: transparent;
    }

    &__divider:last-child {
      display: none;
    }
    &__icon {
      font-size: @border-radius-xlarge;
      color: var(--text-color);
      cursor: pointer;
    }
  }
}
//Toolbar
.go4153082118 {
  background-color: var(--toolbar-color);
  border-radius: @border-radius-xxlarge;
  padding: @tool-bar-padding;
  box-shadow: none;
  color: var(--text-color);
}

.bp4-button.bp4-minimal:active, .bp4-button.bp4-minimal.bp4-active{
  box-shadow: none;
  outline: none;
}

//content
.go3009151065 {
  background-color: var(--content-color);
  border-radius: @border-radius-xlarge;
  margin-top: @content-margin-top;
}

//toast
.bp4-toast {
  align-items: center;
  border-radius: 10px;
}

//element container
.reload__button__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.714em 0em;
}

.input__field__container {
  display: flex;
  flex-direction: column;
  gap: 0.714em;
  margin-bottom: 2.857em;
}


//tool tip
.bp4-tooltip2 {
  border-radius: 4px;

}

.bp4-tooltip2 .bp4-popover2-content  {
  padding: 0.286em 0.571em 0.286em 0.571em;
  border-radius: 4px;
  z-index: 10;
}

.bp4-transition-container .bp4-popover-enter-done{
  padding: 0.286em 0.571em 0.286em 0.571em;
  border-radius: 4px;
  z-index: 5;
}

//tab 
.bp4-tab-list{
  padding-bottom: 1.429em;
  box-shadow: none;
  outline: none;
}

.bp4-tab-panel_undefined_font{
  box-shadow: none;
  outline: none;
}

.bp4-tab{
  box-shadow: none;
  outline: none;
}


//checkbox
input[type=checkbox] {
  accent-color: #16885F;
}

.custom__checkbox{
  outline: none;
  cursor: pointer;
  width: 15px;
  height: 15px;
}
