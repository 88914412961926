.silder {
  width: 100%;
  text-align: center;
  overflow: hidden;
  margin-bottom: 1.429em;

  &::-webkit-scrollbar {
    display: none;
  }

  &__container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    gap: 10px;
    overflow-y: hidden;

    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
}

.layout__chips {
  height: 2.286em;
  width: 6.143em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0em @layout-gap 0em @layout-gap;
  border-radius: 40px;
  margin: 0em @layout-gap;
  background: var(--layout-background);
  cursor: pointer;
}

.template__chips {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 14.286em;
  height: 10em;
  border-radius: @border-radius-medium;
  position: relative;
  margin: 0em 0.357em;
  cursor: pointer;
  overflow: hidden;
  &__shimmer {
    width: 14.286em;
    height: 10em;
    object-fit: cover;
    box-shadow: 0 0 20px 2px var(--box-showdow-color);
    border-radius: @border-radius-medium;
  }
}

.template__chips__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 0 20px 2px var(--box-showdow-color);
  border-radius: @border-radius-medium;
  margin-bottom: 8px;
}

.template__chips__text {
  text-align: left;
  display: block;
  font-weight: 500;
  margin: 0em 0.357em;
}
