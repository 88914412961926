.section-side-panel {
  height: @panel-height;
  background-color: var(--background-color);
  border-radius: @border-radius-xlarge;
  margin-top: @panel-margin-top;
  margin-left: @panel-margin-left;
  box-shadow: 0 0 20px 0px var(--box-showdow-color);
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.open-panel {
  .section-side-panel;
  width: @panel-open-width;
  opacity: @panel-open-opacity;
  transition: 0.2s ease-in-out;
}

.close-panel {
  width: @panel-close-width;
  opacity: @panel-close-opacity;
  transform: @panel-close-transform;
  transition: 0.2s ease-in-out;
}

.panel-title-container {
  display: flex;
  justify-content: space-between;
  margin-top: @panel-title-container-margin-top;
  padding: 20px 20px 0px 20px;
}

.panel-title {
  font-size: @font-size-medium;
  font-weight: @font-weight-default;
}

.scrollable-container {
  height: calc(100% - 2.5em); 
  overflow-x: hidden;
  overflow-y: auto;
  padding: @panel-padding;

  &::-webkit-scrollbar {
    display: none;
  }
}