.img__chip {
  background-color:@image-chips-background-color;
  width: @image-chips-width;
  height: @image-chips-height;
  border-radius: @image-chips-border-radius;
  position: absolute;
  left: @image-chips-position;
  top: @image-chips-position;
  color: @image-chips-color;
  z-index: 1;

  &__container{
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: @image-chips-container-margin-top;
     gap:@image-chips-container-gap;
  }



}
