.product {
  &__filter {
    display: flex;
    align-items: center;
    gap: @product-filter-gap;
  }

  &__filter__dropdown {
    width: @product-filter-width;
    height: @product-filter-height;
    background-color: var(--background-color);
    border-radius: @product-filter-border-radius;
    box-shadow: 0 0 20px 0px @light-box-showdow-color;
    padding: @product-filter-padding;
  }

  &__filter__sorting {
    border-radius: @product-filter-border-radius;
    box-shadow: 0 0 20px 0px @light-box-showdow-color;
  }

  &__filter__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  &__icon {
    font-size: @product-icon-font-size;
    cursor: pointer;
    transition: 0.2s ease-out;
    color: var(--border-color);
    border: @border-width-default @border-type-default #c5c7ca;
    border-radius: @border-radius-medium;
    width: @product-icon-width;
    padding: @product-icon-padding;
  }

  &__icon:hover {
    transition: 0.2s ease-in;
    transform: scale(1.04);
  }

  &__attribute {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: @border-radius-medium;
    width: @product-attribute-width;
    padding: @product-attribute-padding;
  }

  &__change__attribute {
    color: var(--primary-color);
    font-weight: @font-weight-default;
    cursor: pointer;
  }

  &__change__attribute--inactive {
    color: #94a59f;
  }

  &__modal__container {
    display: flex;
    gap: @product-modal-gap;

    .bp4-control:not(.bp4-align-right) {
      padding: @product-modal-padding;
    }
  }

  &__modal__btn {
    display: flex;
    justify-content: center;
    padding: @product-modal-btn-padding;
  }

  &__modal__checkbox {
    max-height: @product-checkbox-container-height;
    overflow-y: scroll;
    width: 20em;
  }

  &__modal__dnd {
    height: @product-dnd-height;
    padding: @product-dnd-padding;
    background-color: @product-dnd-background-color;
    border-radius: @product-dnd-border-radius;
    width: @product-dnd-width;

    &__area {
      height: @product-dnd-area-height;
      overflow-y: scroll;
    }

    &__chip {
      padding: @product-chip-padding;
      display: flex;
      align-items: center;
    }
  }

  &__card__grid {
    display: grid;
    grid-template-columns: repeat(2, 3fr);
    row-gap: @product-grid-row-gap;
    column-gap: @product-grid-column-gap;
    margin: @product-grid-margin;
    grid-auto-flow: dense;
  }

  &__card__collapse {
    margin-top: @product-collapse-margin-top;
    grid-column: 1 / -1;
    background-color: @product-collapse-background-color;
    border-radius: @border-radius-medium;
    cursor: pointer;
  }

  &__collapse__container {
    display: flex;
    justify-content: space-between;
    padding: @product-collapse-container-padding;
    color: white;
  }

  &__collapse__icon {
    position: absolute;
    color: @product-collapse-background-color;
    top: @product-collapse-icon-top;
  }

  &__carousel {
    height: @product-carousel-height;
    display: flex;
    gap: @product-carousel-gap;
    border-radius: @product-carousel-border-radius;
    color: var(--text-color);
    margin-bottom: @product-carousel-margin-bottom;
    padding: @product-carousel-padding;
    overflow: hidden;
    scroll-behavior: smooth;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__carousel__item {
    border-radius: @product-carousel-border-radius;
    overflow: hidden;
  }

  &__carousel__img {
    width: @product-carousel-img-width;
    height: @product-carousel-img-height;
    object-fit: cover;
    border-radius: @border-radius-medium;
    &__shimmer {
      width: @product-carousel-img-width;
      height: @product-carousel-img-height;
      object-fit: cover;
      border-radius: @border-radius-medium;
    }
  }

  &__card__img {
    position: absolute;
    top: @product-card-img-postion;
    left: @product-card-img-postion;
    width: @product-card-img-width;
    height: @product-card-img-height;
    object-fit: cover;
  }

  &__img__container {
    position: relative;
    overflow: hidden;
    height: @product-card-img-container-height;
  }

  &__card__content {
    padding: @product-card-content-padding;
  }
}

// //filters
// .bp4-popover2-transition-container {
//   //transform: translate(-3em, 0.714em);
//   z-index: 5;
// }

.bp4-popover2 .bp4-popover2-content,
.bp4-menu {
  border-radius: @product-popover-border-radius;
  padding: @product-popover-padding;
}

.bp4-menu-item {
  padding: @product-menu-padding;
}

.bp4-menu-item:hover {
  background-color: var(--secondary-color);
}

.bp4-menu-item.bp4-selected,
.bp4-menu-item.bp4-selected:hover,
.bp4-menu-item.bp4-selected:active {
  background-color: @light-secondary-color;
  color: black;
}
