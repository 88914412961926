.spinner {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #16885F; /* Blue */
  border-radius: 50%;
  width: 3.571em;
  height: 3.571em;
  animation: spin 0.5s linear infinite;
}

.spinner__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.714em 0em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
