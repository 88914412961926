.bp4-button {
  height: @button-height-default;
  border-radius: @border-radius-medium;
  font-size: @font-size-small;
  font-family: 'Satoshi-Bold';
  font-weight: @font-weight-default;
  transition: 0.2s ease-out;
}

.bp4-overflow-list{
  align-items: center;
  padding: 0.357em;
}