.btn {
  width: @button-width-default;
  height: @button-height-default;
  border-radius: @border-radius-medium;
  padding: @padding-default;
  font-size: @font-size-small;
  font-family: 'Satoshi-Bold';
  font-weight: @font-weight-default;
  transition: 0.2s ease-out;
  box-shadow: none;
  outline: none;

  &__primary {
     background-color: var(--primary-color);
     color: var(--alternate-text-color);
  }

  &__secondary {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }

  &__primary:hover{
    background-color: rgb(0, 68, 45);
    transition: 0.2s ease-in;
 } 


  &__secondary:hover{
     background-color: rgb(204, 230, 223);
     transition: 0.2s ease-in;
  } 

  &__download {
    width: @download-button-width;
    margin-top: @download-button-margin-top;
    padding: @download-button-padding;
    margin-bottom: @download-button-margin-bottom;
  }

  &__disable{
      border: @border-width-default @border-type-default @dark-box-showdow-color;
  }
}

.close__button {
  font-size: @font-size-xlarge;
  color: var(--icon-color);
  cursor: pointer;
}

.confirm__button {
  width: 21.429em;
  height: 2.857em;
  margin: 0.7em;
}
