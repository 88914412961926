.navbar {
    box-shadow: none;
    background-color: var(--background-color);
    color: var(--text-color);
  
    &__logo__container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap:@navbar-logo-container-gap;
      font-size: @font-size-large;
    }

    &__name{
      color: black;
      opacity: 0.4;
      font-size: 20px; 
    }

    &__name__divider{
      width: 0.125em;
      height: 0.9em;
      background-color: black;
      opacity: 0.4;
      display: inline-block;
     }
  
    &__item__container {
      gap: @navbar-item-container-gap;
    }
  
    &__item__icon{
      font-size: @border-radius-xlarge;
      color: var(--text-color);
      cursor: pointer;
    }
  
    &__divider {
      margin: @navbar-divider-margin;
    }
  }
  
  .bp4-navbar {
    padding: @navbar-padding;
    box-shadow: none;
    background-color: var(--background-color);
  }