//input 
.bp4-input-group{
    margin: 0.714em 0em;
  }
  
  .bp4-input-group .bp4-input:not(:first-child){
     border-radius: 8px;
     margin-top: 0.714em;
     height: 2.857em;
     border: @border-width-default @border-type-default @light-box-showdow-color;
  }

  
  .bp4-input-group .bp4-input-action:first-child, .bp4-input-group > .bp4-input-left-container:first-child, .bp4-input-group > .bp4-button:first-child, .bp4-input-group > .bp4-icon:first-child{
    top: 15px;
  }