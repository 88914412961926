.modal {
  padding: @modal-padding;
  border-radius: @border-radius-large;
  background-color: white;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: @modal-header-padding;
  }

  &__title{
    display: flex; 
  }

  &__loading__container{
   padding: 1.429em;
   text-align: center;
  }

  &__loading__text {
    font-size: @font-size-large;
    font-weight: @font-weight-default;
    margin-bottom: 1em;
    text-align: center;
  }

  &__loading__subText{
    margin-bottom: 2em;
    opacity: 0.6;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.product__attribute__modal {
  width: @product-modal-btn-width;
}

.warning__modal {
  width: 32.143em;
}

.layout__warning__modal__message{
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 18px;
    line-height: 1.6;
}

.bp4-dialog-body-scroll-container{
  overflow: hidden;
  max-height: fit-content;
}