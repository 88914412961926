.input{
    border-radius: @border-radius-medium;
    border: @border-width-default @border-type-default @input-border-color;
    font-size:@input-font-size;
    margin:@input-margin;
    width:@input-width;
    height: @input-height;
    padding: @input-padding;
    background: @input-background;
    box-shadow: none;
    outline: none;
}


.input__error__msg {
   color: @input-error-color;
   padding: 0em;
   margin: 0em;
 }
 
.input::placeholder{
   color: @input-placeholder-color;
}

.input__title{
  font-weight: 500;
}
