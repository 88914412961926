.screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
  gap: 20px;
  line-height: 1.5;

  &__img {
    width: 50%;
  }

  &__text {
    font-weight: @font-weight-default;
    font-size: 24px;
    text-align: center;
    width: 21em;
  }

  &__sub__text {
    font-size: @font-size-medium;
    text-align: center;
    opacity: 0.6;
  }

  &__button__container{
     display: flex;
     gap: 15px;
  }
}
