// src/assets/less/base/_themes.less
.light-theme {
  --primary-color: @light-primary-color;
  --secondary-color: @light-secondary-color;
  --background-color: @light-background-color;
  --text-color: @light-text-color;
  --alternate-text-color: @light-alternate-text-color;
  --toolbar-color:@light-custom-toolbar-color;
  --content-color:@light-custom-content-color;
  --box-showdow-color:@light-box-showdow-color;
  --border-color:@light-border-color;
  --icon-color:@light-icon-color;
  --layout-background: @light-layout-background;
}

.dark-theme {
  --primary-color: @dark-primary-color;
  --secondary-color: @dark-secondary-color;
  --background-color: @dark-background-color;
  --text-color: @dark-text-color;
  --alternate-text-color: @dark-alternate-text-color;
  --toolbar-color:@dark-custom-toolbar-color;  
  --content-color:@dark-custom-content-color;
  --box-showdow-color:@dark-box-showdow-color;
  --border-color:@dark-border-color;
  --icon-color:@dark-icon-color;
  --layout-background: @dark-layout-background;
}
