.preview__card__shimmer {
  width: 9.643em;
  height: 5em;
  border-radius: @border-radius-medium;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(189, 200, 206);
  }

  100% {
    background-color: rgb(226, 231, 233);
  }
}
