.filter-chip {
  display: inline-flex;
  align-items: center;
  padding: 0.5em;
  border-radius: 50px;
  background-color: #eee;
  margin-right: 0.5em;
  margin-bottom: 0.8em;
}

.remove-btn {
  border: none;
  background: transparent;
  color: #999;
  margin-left: 0.5em;
  font-size: 0.8em;
  cursor: pointer;
}

.remove-btn:hover {
  color: #333;
}