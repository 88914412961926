.card {
  border-radius: @border-radius-medium;

  &__slide {
    width: @slide-card-width;
    height: @slide-card-height;
    margin-bottom: 1.429em;
  }

  &__slide--active{
     border: 1.15px @border-type-default #25282D;
     box-shadow: 0 0 20px 2px var(--box-showdow-color);
  }

  &__product {
    display: flex;
    flex-direction: column;
    height: @product-card-height;
    border-radius: @border-radius-medium;
    cursor: pointer;
    overflow: hidden;
    transition: 0.1s ease-in;
    padding: @product-card-padding;
    position: relative;
  }

  &__template {
    background-color: @product-card-background-color;
    border: none;
    height: 14.286em;
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;
    margin:1.429em 0em;

    &__shimmer {
      height:11.071em;
      width:17.857em;
    }
  }

  &__product--active {
    border: @border-width-default @border-type-default var(--border-color);
  }

  &__product--inactive {
    border: 1px solid transparent;
  }

  &__product:hover{
    box-shadow: 0 0 20px 0px rgba(179, 177, 177, 0.353);
  }

  &__layout {
    background-color: #f5f5f5;
    border: none;
    height: 14.286em;
    width: 100%;
    cursor: pointer;
    position: relative;

    &__shimmer {
      height: 11em;
      width: 100%;
    }
  }

  &__layout--active {
    border: 2px @border-type-default var(--border-color);
  }

  &__layout--inactive {
    border: 1px solid transparent;
  }

  &__layout:hover{
    box-shadow: 0 0 20px 2px rgba(179, 177, 177, 0.551)
  }

  &__layout__layer{
    background-color: grey ;
    opacity: 0.5;
    border-radius: @border-radius-medium;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: 0.3s ease-in-out;
    width: 100%;
  } 

  &__upload{
      width: 9.286em;
      height: 8.929em;
      margin: 0.071em;
      cursor: pointer;
      position: relative;
  }
}

.preview__total_slides{
  padding-top:0.571em;
  padding-left: 0.571em;
}
//slide card
.preview__card {
  overflow: scroll;
  overflow-x:hidden;
  padding: @slide-card-padding;

  &__container {
    display: flex;
    gap: @slide-card-gap;
    margin: @slide-card-container-margin;
    cursor: pointer;
  }

  &__number {
    background-color: var(--text-color);
    opacity: @slide-card-number-opactity;
    width: @slide-card-number-width;
    height: @slide-card-number-height;
    border-radius: @slide-card-number-border-radius;
    color: var(--alternate-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.carousel {
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  gap: 20px;


  &:hover &__container {
    animation: carousel-scroll 2s linear infinite;
  }

  @keyframes carousel-scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200%);
    }
  }
}
